<template>
    <small v-html="getText()"></small>
</template>

<script>
export default {
    props: [
        'product',
        'short'
    ],
    methods: {
        getText() {
            if (this.short === "1") {
               return this.product.availability.short_text ?? "";
            }
            return this.product.availability.text;
        }
    }
};
</script>
