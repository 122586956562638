import { markRaw, reactive, toRefs } from 'vue';

const state = reactive({
    modals: {},
});

function openModal(id, component, props = {}) {
    const storedExpires = localStorage.getItem(id);
    if (storedExpires) {
        const expires = parseInt(storedExpires, 10);
        const now = Date.now();

        if (now < expires) {
            return;
        } else {
            localStorage.removeItem(id);
        }
    }
    state.modals[id] = { component: markRaw(component), props };
}

function closeModal(id, forever = false) {
    if (forever) {
        const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
        const expires = Date.now() + oneWeekInMilliseconds;
        localStorage.setItem(id, expires.toString());
    }

    delete state.modals[id];
}

function modal(id) {
    if (state.modals[id]) {
        return state.modals[id].component;
    }
    return null;
}

function modalIsOpen(id) {
    return state.modals[id] !== undefined;
}

function modalProps(id) {
    return state.modals[id] ? state.modals[id].props : {};
}

export function useModal() {
    return {
        ...toRefs(state),
        openModal,
        closeModal,
        modalIsOpen,
        modal,
        modalProps,
    };
}
