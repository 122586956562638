import { getAlternateHref } from "../composables/getAlternateHref.js";
import { useLocalePath } from '#imports';


export default defineNuxtPlugin((nuxtApp) => {
    const localePath = useLocalePath();

    nuxtApp.hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
        const routeName = nuxtApp.$router.currentRoute.value.name.split('_')[0];
        const allowedRoutes = ['glossary-code', 'blog-code'];

        if (allowedRoutes.includes(routeName)) {
            const defaultPageName = routeName.split('-')[0];
            const alternateHref = getAlternateHref(newLocale) || localePath({name: defaultPageName});

            if (alternateHref) {
                nuxtApp.$router.push(alternateHref);
            }
        }
    });
});
