<template>
    <template v-if="modalIsOpen(id)">
        <div
            class="modal fade show d-block"
            tabindex="-1"
            role="dialog"
            :id="id"
            @mousedown="handleMouseDown" @mouseup="handleMouseUp"
        >
            <div class="modal-dialog" :class="modalDialogClass" role="document">
                <div
                    class="modal-content"
                    :class="[modalContentClass, { 'rounded-4': rounded }]"
                    ref="modalContent"
                >
                    <slot name="header" v-if="showHeader">
                        <div class="modal-header" :class="modalHeaderClass">
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                        </div>
                    </slot>

                    <slot name="modal-body">
                        <div class="modal-body" :class="modalBodyClass">
                            <slot/>
                        </div>
                    </slot>

                    <slot name="footer" v-if="showFooter">
                        <div class="modal-footer" :class="modalFooterClass" v-if="showFooter">
                            <button type="submit" class="btn btn-primary" @click="submitForm">
                                <i v-if="submitButtonIcon" :class="`fa fa-${submitButtonIcon}`"></i>
                                {{$t('Save')}}
                            </button>
                            <button type="button" class="btn btn-outline-primary" @click="close">{{$t('Close')}}</button>
                        </div>
                    </slot>
                </div>
            </div>
        </div>


        <div class="modal-backdrop show fade"></div>
    </template>

</template>

<script setup>
import {useModal} from "../../stores/ModalStore.js";

const props = defineProps({
    id: String,
    title: String,
    modalDialogClass: String,
    modalContentClass: String,
    modalBodyClass: String,
    modalHeaderClass: String,
    modalFooterClass: String,
    submitButtonIcon: String,
    form: Object,
    formUrl: String,
    showHeader: {
        type: Boolean,
        default: true
    },
    showFooter: {
        type: Boolean,
        default: true
    },
    rounded: {
        type: Boolean,
        default: true
    },
    autoclose: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['submit']);

const {closeModal, modalIsOpen } = useModal();

const isClickStartedOutside = ref(false);
const modalContent = ref(null);

function close() {
    closeModal(props.id);
}

function submitForm() {
    emit('submit');
}

function handleMouseDown(event) {
    if (event.button !== 0) {
        return;
    }
    isClickStartedOutside.value = !modalContent.value.contains(event.target);
}

function handleMouseUp(event) {
    if (!props.autoclose) {
        return;
    }
    if (event.button !== 0) {
        return;
    }
    if (isClickStartedOutside.value && !modalContent.value.contains(event.target)) {
        close();
    }
}

function emitOk() {
    close();
}
</script>

<style scoped>

</style>
