<script setup>
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const props = defineProps({
    amount: String,
    class: String
});

const isEnglish = computed(() => locale.value === 'en');
</script>

<template>
    <div :class="['promo-text-container', 'd-md-block', 'text-center', 'pb-0', 'pb-md-4', 'promo-info-' + locale, { 'nowrap': isEnglish }]">
        <p :class="['fw-bold', 'mb-0', 'promo-text', props.class]">
            {{ $t('promo.info.text1', { amount: $formatter.price(props.amount, 'EUR', 0) }) }}
        </p>
        <p :class="['fw-bold', 'ms-2', 'ms-md-0', 'promo-text', props.class]">
            {{ $t('promo.info.text2') }}
        </p>
    </div>
</template>

<style scoped>
.promo-text-container.nowrap {
    white-space: nowrap;
}
</style>
