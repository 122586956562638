import { default as _91_46_46_46slug_93IBX93HJXYeMeta } from "/usr/src/nuxtapp/pages/[locale]/[...slug].vue?macro=true";
import { default as _404ZtEoYGGCBVMeta } from "/usr/src/nuxtapp/pages/404.vue?macro=true";
import { default as _91code_93RS4eAp7a0fMeta } from "/usr/src/nuxtapp/pages/blog/[code].vue?macro=true";
import { default as index5gMiZxK04LMeta } from "/usr/src/nuxtapp/pages/blog/index.vue?macro=true";
import { default as contact_45ush1OPrrqyRpMeta } from "/usr/src/nuxtapp/pages/contact-us.vue?macro=true";
import { default as faqGJMKCsTbsaMeta } from "/usr/src/nuxtapp/pages/faq.vue?macro=true";
import { default as _91code_93Yya6eRvsDvMeta } from "/usr/src/nuxtapp/pages/glossary/[code].vue?macro=true";
import { default as indexD8vE1IjrEQMeta } from "/usr/src/nuxtapp/pages/glossary/index.vue?macro=true";
import { default as index9D2IrMu3ubMeta } from "/usr/src/nuxtapp/pages/index.vue?macro=true";
import { default as loginuxquRycQutMeta } from "/usr/src/nuxtapp/pages/login.vue?macro=true";
import { default as registerQczl10k7cRMeta } from "/usr/src/nuxtapp/pages/register.vue?macro=true";
import { default as searchLYII3OUDR7Meta } from "/usr/src/nuxtapp/pages/search.vue?macro=true";
export default [
  {
    name: "locale-slug___en",
    path: "/en/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___es",
    path: "/es/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___fr",
    path: "/fr/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___ru",
    path: "/ru/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___it",
    path: "/it/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___de",
    path: "/de/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-slug___pl",
    path: "/pl/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93IBX93HJXYeMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[locale]/[...slug].vue")
  },
  {
    name: "404___en",
    path: "/en/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___es",
    path: "/es/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___ru",
    path: "/ru/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___it",
    path: "/it/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___de",
    path: "/de/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "404___pl",
    path: "/pl/404",
    meta: _404ZtEoYGGCBVMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/404.vue")
  },
  {
    name: "blog-code___en",
    path: "/en/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___es",
    path: "/es/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___fr",
    path: "/fr/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___ru",
    path: "/ru/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___it",
    path: "/it/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___de",
    path: "/de/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog-code___pl",
    path: "/pl/blog/:code()",
    meta: _91code_93RS4eAp7a0fMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/[code].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___it",
    path: "/it/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "blog___pl",
    path: "/pl/blog",
    meta: index5gMiZxK04LMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___es",
    path: "/es/contactanos",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___fr",
    path: "/fr/contactez-nous",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___ru",
    path: "/ru/kontakt",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___it",
    path: "/it/contattaci",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___de",
    path: "/de/kontaktieren-sie-uns",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___pl",
    path: "/pl/kontakt",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "glossary-code___en",
    path: "/en/glossary/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___es",
    path: "/es/glosario/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___fr",
    path: "/fr/glossaire/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___ru",
    path: "/ru/glossarii/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___it",
    path: "/it/glossario/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___de",
    path: "/de/lexikon/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary-code___pl",
    path: "/pl/slownik/:code()",
    meta: _91code_93Yya6eRvsDvMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/[code].vue")
  },
  {
    name: "glossary___en",
    path: "/en/glossary",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___es",
    path: "/es/glosario",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___fr",
    path: "/fr/glossaire",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___ru",
    path: "/ru/glossarii",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___it",
    path: "/it/glossario",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___de",
    path: "/de/lexikon",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "glossary___pl",
    path: "/pl/slownik",
    meta: indexD8vE1IjrEQMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/glossary/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/iniciar-sesion",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/connexion",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___ru",
    path: "/ru/vkhod",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/accedi",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/anmeldung",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/logowanie",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___es",
    path: "/es/registro",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/inscription",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___ru",
    path: "/ru/registratsiya",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/registrazione",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___de",
    path: "/de/registrierung",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___pl",
    path: "/pl/rejestracja",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___pl",
    path: "/pl/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  }
]