export const useHighlightedText = (route, text) => {
    const term = route.query.term || route.query.search || '';

    if (term.trim().length === 0) {
        return text;
    }

    let escapedText = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    escapedText = escapedText.split(' ').filter(entry => entry.trim() !== '').join('|');

    return text.replace(new RegExp('(' + escapedText + ')', 'gi'), '<mark>$1</mark>');
};
