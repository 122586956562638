import {useServerFetch} from "./useServerFetch.js";

export async function getFromServer(url, opts = []) {
    const { data, error, pending, response } =  await useServerFetch(url, opts);

    //here we can catch 403, 503 errors
    if (error.value) {
        // if (error.value.statusCode !== 404) {
            throw createError(error.value);
        // }
    }

    return data.value?.data;
}
