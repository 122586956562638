<template>
    <div class="text-center">
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3">
            {{ $t('Page not found') }}
        </p>
    </div>
</template>

<script setup>
useHead({
    title: '404'
})
gtm.openPage('404');
</script>
