import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxtapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/nuxtapp/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/nuxtapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxtapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/usr/src/nuxtapp/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/usr/src/nuxtapp/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/nuxtapp/.nuxt/sentry-client-config.mjs";
import plugin_client_OVoKJro5pc from "/usr/src/nuxtapp/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import titles_dw2T9lEw4h from "/usr/src/nuxtapp/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/usr/src/nuxtapp/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/usr/src/nuxtapp/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/usr/src/nuxtapp/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/nuxtapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/nuxtapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import jquery_SqE5xeO2Mp from "/usr/src/nuxtapp/plugins/jquery.js";
import Bootstrap5_oKY8zzOmw0 from "/usr/src/nuxtapp/plugins/Bootstrap5.js";
import vue_tel_input_GukcJ5wu6r from "/usr/src/nuxtapp/plugins/vue-tel-input.js";
import Formatter_APwM5uFDeo from "/usr/src/nuxtapp/plugins/Formatter.js";
import i18nAlternateHref_client_MVGPh2BSuv from "/usr/src/nuxtapp/plugins/i18nAlternateHref.client.js";
import mitt_ZNXaeqsgM5 from "/usr/src/nuxtapp/plugins/mitt.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_OVoKJro5pc,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  jquery_SqE5xeO2Mp,
  Bootstrap5_oKY8zzOmw0,
  vue_tel_input_GukcJ5wu6r,
  Formatter_APwM5uFDeo,
  i18nAlternateHref_client_MVGPh2BSuv,
  mitt_ZNXaeqsgM5
]