<template>
    <minimal>
        <div class="text-center">
            <h1>{{useError().value.statusMessage}}</h1>
            <p>{{useError().value.data}}</p>
        </div>
    </minimal>
</template>

<script setup>
import Minimal from "./minimal.vue";

useHead({
    title: '403'
})
</script>
