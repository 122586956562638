<template>
    <minimal>
        <div class="text-center">
            <p>{{$t('miscellaneous.service_unavailable')}}</p>
        </div>
    </minimal>
</template>

<script setup>
import Minimal from "./minimal.vue";

useHead({
    title: '503'
})
</script>
