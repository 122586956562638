export const getAlternateHref = (lang) => {
    const links = document.querySelectorAll('link[rel="alternate"][hreflang]');
    for (const link of links) {
        if (link.getAttribute("hreflang") === lang) {
            const url = new URL(link.getAttribute("href"));
            return url.pathname + url.search;
        }
    }

    return null;
};
