
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "fr",
  "ru",
  "it",
  "de",
  "pl"
]

export const localeLoaders = {
  "en": [],
  "es": [],
  "fr": [],
  "ru": [],
  "it": [],
  "de": [],
  "pl": []
}

export const vueI18nConfigs = [
  () => import("../config/i18n.config.js?hash=5224f27c&config=1" /* webpackChunkName: "~_config_i18n_config_js_5224f27c" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "~/config/i18n.config.js",
  "locales": [
    {
      "code": "en",
      "language": "en"
    },
    {
      "code": "es",
      "language": "es"
    },
    {
      "code": "fr",
      "language": "fr"
    },
    {
      "code": "ru",
      "language": "ru"
    },
    {
      "code": "it",
      "language": "it"
    },
    {
      "code": "de",
      "language": "de"
    },
    {
      "code": "pl",
      "language": "pl"
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "blog/[code]": {
      "de": "/blog/[code]",
      "en": "/blog/[code]",
      "es": "/blog/[code]",
      "fr": "/blog/[code]",
      "it": "/blog/[code]",
      "ru": "/blog/[code]"
    },
    "blog/index": {
      "de": "/blog",
      "en": "/blog",
      "es": "/blog",
      "fr": "/blog",
      "it": "/blog",
      "ru": "/blog"
    },
    "contact-us": {
      "de": "/kontaktieren-sie-uns",
      "en": "/contact-us",
      "es": "/contactanos",
      "fr": "/contactez-nous",
      "it": "/contattaci",
      "pl": "/kontakt",
      "ru": "/kontakt"
    },
    "glossary/[code]": {
      "de": "/lexikon/[code]",
      "en": "/glossary/[code]",
      "es": "/glosario/[code]",
      "fr": "/glossaire/[code]",
      "it": "/glossario/[code]",
      "pl": "/slownik/[code]",
      "ru": "/glossarii/[code]"
    },
    "glossary/index": {
      "de": "/lexikon",
      "en": "/glossary",
      "es": "/glosario",
      "fr": "/glossaire",
      "it": "/glossario",
      "pl": "/slownik",
      "ru": "/glossarii"
    },
    "login": {
      "de": "/anmeldung",
      "en": "/login",
      "es": "/iniciar-sesion",
      "fr": "/connexion",
      "it": "/accedi",
      "pl": "/logowanie",
      "ru": "/vkhod"
    },
    "register": {
      "de": "/registrierung",
      "en": "/register",
      "es": "/registro",
      "fr": "/inscription",
      "it": "/registrazione",
      "pl": "/rejestracja",
      "ru": "/registratsiya"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "seo": true,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en",
    "files": []
  },
  {
    "code": "es",
    "language": "es",
    "files": []
  },
  {
    "code": "fr",
    "language": "fr",
    "files": []
  },
  {
    "code": "ru",
    "language": "ru",
    "files": []
  },
  {
    "code": "it",
    "language": "it",
    "files": []
  },
  {
    "code": "de",
    "language": "de",
    "files": []
  },
  {
    "code": "pl",
    "language": "pl",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
