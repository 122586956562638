<template>
    <minimal>
        <div class="text-center">
            <p>{{$t('miscellaneous.service_unavailable')}}</p>
        </div>
    </minimal>
</template>

<script setup>
import { useError } from '#imports';
import { onMounted } from 'vue';
import Minimal from './minimal.vue';

useHead({
    title: 'Error'
})

onMounted(() => {
    const error = useError();
    throw new Error(error.value?.statusCode + ': '+error.value?.statusMessage);
});
</script>



