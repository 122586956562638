<template>
    <Error503 v-if="error.statusCode === 503"/>
    <template v-else-if="error.statusCode === 404">
        <default>
            <Error404/>
        </default>
    </template>
    <Error403 v-else-if="error.statusCode === 403"/>
    <ErrorPage v-else/>
</template>

<script setup>
import Error503 from "./errors/Error503.vue";
import Error404 from './errors/Error404.vue';
import Error403 from './errors/Error403.vue';
import ErrorPage from './errors/ErrorPage.vue';
import Default from './layouts/default.vue';


const error = useError();
</script>


