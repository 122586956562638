import validate from "/usr/src/nuxtapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import _100_45decode_45path_45global from "/usr/src/nuxtapp/middleware/100.decode-path.global.js";
import _105_45redirect_45trailing_45slash_45global from "/usr/src/nuxtapp/middleware/105.redirect-trailing-slash.global.js";
import _161_45navigation_45global from "/usr/src/nuxtapp/middleware/161.navigation.global.js";
import manifest_45route_45rule from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _100_45decode_45path_45global,
  _105_45redirect_45trailing_45slash_45global,
  _161_45navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "110-set-language-prefix-client": () => import("/usr/src/nuxtapp/middleware/110.set-language-prefix.client.js"),
  "catalog-pagination": () => import("/usr/src/nuxtapp/middleware/catalog-pagination.js"),
  "page-404": () => import("/usr/src/nuxtapp/middleware/page-404.js"),
  "page-blog": () => import("/usr/src/nuxtapp/middleware/page-blog.js"),
  "page-glossary": () => import("/usr/src/nuxtapp/middleware/page-glossary.js"),
  "page-index": () => import("/usr/src/nuxtapp/middleware/page-index.js"),
  "page-login": () => import("/usr/src/nuxtapp/middleware/page-login.js"),
  "page-router": () => import("/usr/src/nuxtapp/middleware/page-router.js"),
  "page-search": () => import("/usr/src/nuxtapp/middleware/page-search.js")
}