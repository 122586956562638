export const gtm = {
    openedPages: new Set(),

    push(data) {
        if (process.client) {
            const config = useRuntimeConfig();
            const id = config.public.gtm?.id;

            if (!id) {
                return;
            }

            useGtm().push(toRaw(data));
        }
    },

    openPage(type) {
        if (process.client) {
            this.openedPages.add(type);
            this.push({
                "event": "page.open",
                "type": type
            });
        }
    },

    event(event, value) {
        this.push({
            "event": 'ga-event',
            "eventCategory": event,
            "eventAction": value
        });
    },

    banner(code, name) {
        this.push({
            "event": 'ga-event',
            "eventCategory": 'Banner',
            "eventAction": 'click',
            "eventLabel": name,
            "eventValue": code
        });
    }
};
