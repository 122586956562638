import {useErrorStore} from "../stores/ErrorStore.js";
import {useLocaleStore} from "../stores/LocaleStore.js";

export async function useClientFetch(url, opts = {}) {
    //if app received error code like 503, no need to make any queries
    const nuxtApp = useNuxtApp();
    if (nuxtApp.payload.error) {
        if ([403,503].includes(nuxtApp.payload.error.statusCode)) {
            throw createError(nuxtApp.payload.error)
        }
    }
    const locale = nuxtApp.$i18n?.locale.value;

    if (url.substring(0, 1) !== '/') {
        url = '/' + url;
    }

    let headers = {
        'Accept-Language': locale,
        'Lang': locale,
        'Environment': 'nuxt',
        'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'X-Currency': useLocaleStore().currency?.unit_text
    };

    const token = await useCookie('XSRF-TOKEN');
    if (token.value) {
        headers['X-XSRF-TOKEN'] = token.value;
    }
    useErrorStore().loading = true;
    useErrorStore().cleanErrors();
    try {
        return await $fetch('/api/client' + url, {
            headers: {
                ...headers,
                ...opts?.headers
            },
            ...opts,
        });
    } catch (error) {
        if (error.response?.status === 422) {
            useErrorStore().handleFormErrors(error.data.data.form_validation);
        } else {
            throw error;
        }
    } finally {
        useErrorStore().loading = false;
    }
}
