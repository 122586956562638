export default {
    index(url) {
        return useClientFetch('seo/?url=' + url);
    },
    update(body) {
        return useClientFetch('seo', {
            method: 'POST',
            body
        });
    },
};
